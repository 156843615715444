import { createWebHistory, createRouter } from "vue-router";

export default createRouter({
    history: createWebHistory(),
    routes: [{
        path: "/", 
        redirect: "/dashboard", 
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/dashboard", 
        name: "dashboard", 
        meta: {
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/DashboardAnalytics.vue")
    }, {
        path: "/totemFuncionario", 
        name: "totemFuncionario", 
        meta: {
            title: "Totem manipulado pelo Funcionário", 
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "totemFuncionario" */ "@/pages/TotemFuncionario.vue")
    }, {
        path: "/totemPaciente",
        name: "totemPaciente",
        meta: {
            title: "Totem manipulado pelo Paciente", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "totemPaciente" */ "@/pages/TotemPaciente.vue")
    }, {
        path: "/chamadaNaTV",
        name: "chamadaNaTV",
        meta: {
            title: "Chamada na TV", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "chamadaNaTV" */ "@/pages/ChamadaNaTV.vue")
    }, {
        path: "/agenda", 
        name: "agenda", 
        meta: {
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "agenda" */ "@/pages/AgendaSpirit.vue")
    }, {
        path: "/agendaGoogle",
        name: "agendaGoogle",
        meta: {
            title: "Calendário", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "agendaGoogle" */ "@/pages/AgendaSpirit.vue")
    }, {
        path: "/filaPep",
        name: "filaPep",
        meta: {
            title: "Fila do Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "filaPep" */ "@/pages/FilaPEP.vue")
    }, {
        path: "/filaLaudo",
        name: "filaLaudo",
        meta: {
            title: "Fila do Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "filaLaudo" */ "@/pages/FilaPEP.vue")
    }, {
        path: "/repasse",
        name: "repasse",
        meta: {
            title: "Repasse Médico", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/RepasseMedico.vue")
    }, {
        path: "/atencaoContinua", 
        name: "atencaoContinua", 
        meta: {
            title: "Gestão de Retorno", 
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/AgendaRetorno.vue")
    }, {
        path: "/solicitacaoProcedimento", 
        name: "solicitacaoProcedimento", 
        meta: {
            title: "Solicitação de Procedimento", 
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/SolicitacaoProcedimento.vue")
    }, {
        path: "/pep/:id",
        name: "pep",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pep" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda",
        name: "pepComAgenda",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pepComAgenda" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda/:idAgendaExame/:idProduto",
        name: "pepLaudo",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pepLaudo" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda/:idAgendaExame/:idProduto/:idDiversos",
        name: "pepLaudoAlteracao",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pepLaudoAlteracao" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/textos",
        name: "textos",
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "textos" */ "@/pages/CadTextos.vue")
    }, {
        path: "/texto", 
        name: "textoInc", 
        meta: {
            requiresAuth: true
        }, 
        component: () => import(/* webpackChunkName: "textoInc" */ "@/pages/CadTexto.vue")
    }, {
        path: "/texto/:id",
        name: "textoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "textoAlt" */ "@/pages/CadTexto.vue")
    }, {
        path: "/textosEmail",
        name: "textosEmail", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "textosEmail" */ "@/pages/CadTextosEmail.vue")
    }, {
        path: "/textoEmail",
        name: "textoEmailInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "textoEmailInc" */ "@/pages/CadTextoEmail.vue")
    }, {
        path: "/textoEmail/:id",
        name: "textoEmailAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "textoEmailAlt" */ "@/pages/CadTextoEmail.vue")
    }, {
        path: "/turnos",
        name: "turnos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "turnos" */ "@/pages/CadTurnos.vue")
    }, {
        path: "/turno",
        name: "turnoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "turnoInc" */ "@/pages/CadTurno.vue")
    }, {
        path: "/turno/:id",
        name: "turnoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "turnoAlt" */ "@/pages/CadTurno.vue")
    }, {
        path: "/unidades",
        name: "unidades", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "unidades" */ "@/pages/CadUnidades.vue")
    }, {
        path: "/unidade",
        name: "unidadeInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "unidadeInc" */ "@/pages/CadUnidade.vue")
    }, {
        path: "/unidade/:id",
        name: "unidadeAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "unidadeAlt" */ "@/pages/CadUnidade.vue")
    }, {
        path: "/staffMedicos",
        name: "staffMedicos",
        meta: {
            title: "Inclusão Cadastro Staff Médico", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicos" */ "@/pages/CadStaffMedicos.vue")
    }, {
        path: "/staffMedico",
        name: "staffMedicoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicoInc" */ "@/pages/CadStaffMedico.vue")
    }, {
        path: "/staffMedico/:id",
        name: "staffMedicoAlt",
        meta: {
            title: "Alteração Cadastro Staff Médico", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicoAlt" */ "@/pages/CadStaffMedico.vue")
    }, {
        path: "/staffMedicosCategorias",
        name: "staffMedicosCategorias", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicosCategorias" */ "@/pages/CadStaffMedicosCategorias.vue")
    }, {
        path: "/staffMedicoCategoria",
        name: "staffMedicoCategoriaInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicoCategoriaInc" */ "@/pages/CadStaffMedicoCategoria.vue")
    }, {
        path: "/staffMedicoCategoria/:id",
        name: "staffMedicoCategoriaAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "staffMedicoCategoriaAlt" */ "@/pages/CadStaffMedicoCategoria.vue")
    }, {
        path: "/contatosExternos",
        name: "contatosExternos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "contatosExternos" */ "@/pages/CadContatosExternos.vue")
    }, {
        path: "/contatoExterno",
        name: "contatoExternoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "contatoExternoInc" */ "@/pages/CadContatoExterno.vue")
    }, {
        path: "/contatoExterno/:id",
        name: "contatoExternoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "contatoExternoAlt" */ "@/pages/CadContatoExterno.vue")
    }, {
        path: "/servicos",
        name: "servicos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "servicos" */ "@/pages/CadServicos.vue")
    }, {
        path: "/servico",
        name: "servicoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "servicoInc" */ "@/pages/CadServico.vue")
    }, {
        path: "/servico/:id",
        name: "servicoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "servicoAlt" */ "@/pages/CadServico.vue")
    }, {
        path: "/procedimentos",
        name: "procedimentos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "procedimentos" */ "@/pages/CadProcedimentos.vue")
    }, {
        path: "/procedimento",
        name: "procedimentoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "procedimentoInc" */ "@/pages/CadProcedimento.vue")
    }, {
        path: "/procedimento/:id",
        name: "procedimentoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "procedimentoAlt" */ "@/pages/CadProcedimento.vue")
    }, {
        path: "/produtos",
        name: "produtos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "produtos" */ "@/pages/CadProdutos.vue")
    }, {
        path: "/produto",
        name: "produtoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "produtoInc" */ "@/pages/CadProduto.vue")
    }, {
        path: "/produto/:id",
        name: "produtoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "produtoAlt" */ "@/pages/CadProduto.vue")
    }, {
        path: "/pacientes",
        name: "pacientes", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pacientes" */ "@/pages/CadPacientes.vue")
    }, {
        path: "/paciente",
        name: "pacienteInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pacienteInc" */ "@/pages/CadPaciente.vue")
    }, {
        path: "/paciente/:id",
        name: "pacienteAlt",
        meta: {
            title: "Cadastro do Paciente", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "pacienteAlt" */ "@/pages/CadPaciente.vue")
    }, {
        path: "/convenios",
        name: "convenios", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "convenios" */ "@/pages/CadConvenios.vue")
    }, {
        path: "/convenio",
        name: "convenioInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "convenioInc" */ "@/pages/CadConvenio.vue")
    }, {
        path: "/convenio/:id",
        name: "convenioAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "convenioAlt" */ "@/pages/CadConvenio.vue")
    }, {
        path: "/usuarios",
        name: "usuarios", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "usuarios" */ "@/pages/CadUsuarios.vue")
    }, {
        path: "/usuario",
        name: "usuarioInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "usuarioInc" */ "@/pages/CadUsuario.vue")
    }, {
        path: "/usuario/:id",
        name: "usuarioAlt",
        meta: {
            title: "Alteração de Usuário", 
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "usuarioAlt" */ "@/pages/CadUsuario.vue")
    }, {
        path: "/perfil",
        name: "perfil", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "perfil" */ "@/pages/UsuarioPerfil.vue")
    }, {
        path: "/locaisAtendimentoCategorias",
        name: "locaisAtendimentoCategorias", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "locaisAtendimentoCategorias" */ "@/pages/CadLocaisAtendimentoCategorias.vue")
    }, {
        path: "/localAtendimentoCategoria",
        name: "localAtendimentoCategoriaInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "localAtendimentoCategoriaInc" */ "@/pages/CadLocalAtendimentoCategoria.vue")
    }, {
        path: "/localAtendimentoCategoria/:id",
        name: "localAtendimentoCategoriaAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "localAtendimentoCategoriaAlt" */ "@/pages/CadLocalAtendimentoCategoria.vue")
    }, {
        path: "/categoriasTextos",
        name: "categoriasTextos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "categoriasTextos" */ "@/pages/CadCategoriasTextos.vue")
    }, {
        path: "/categoriaTexto",
        name: "categoriaTextoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "categoriaTextoInc" */ "@/pages/CadCategoriaTexto.vue")
    }, {
        path: "/categoriaTexto/:id",
        name: "categoriaTextoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "categoriaTextoAlt" */ "@/pages/CadCategoriaTexto.vue")
    }, {
        path: "/configuracoes",
        name: "configuracoes", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ "@/pages/ConfiguracoesSistema.vue")
    }, {
        path: "/redirect/:rota",
        name: "redirect", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "redirect" */ "@/pages/ComponentRedirect.vue")
    }, {
        path: "/locais",
        name: "locais", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "locais" */ "@/pages/CadLocais.vue")
    }, {
        path: "/local",
        name: "localInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "localInc" */ "@/pages/CadLocal.vue")
    }, {
        path: "/local/:id",
        name: "localAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "localAlt" */ "@/pages/CadLocal.vue")
    }, {
        path: "/tabelasPrecos",
        name: "tabelasPrecos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "tabelasPrecos" */ "@/pages/CadTabelasPrecos.vue")
    }, {
        path: "/tabelaPreco",
        name: "tabelaPrecoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "tabelaPrecoInc" */ "@/pages/CadTabelaPreco.vue")
    }, {
        path: "/tabelaPreco/:id",
        name: "tabelaPrecoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "tabelaPrecoAlt" */ "@/pages/CadTabelaPreco.vue")
    }, {
        path: "/classificacaoGuias",
        name: "classificacaoGuias", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoGuias" */ "@/pages/CadClassificacaoGuias.vue")
    }, {
        path: "/classificacaoGuia",
        name: "classificacaoGuiaInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoGuiaInc" */ "@/pages/CadClassificacaoGuia.vue")
    }, {
        path: "/classificacaoGuia/:id",
        name: "classificacaoGuiaAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoGuiaAlt" */ "@/pages/CadClassificacaoGuia.vue")
    }, {
        path: "/classificacaoAtendimentos",
        name: "classificacaoAtendimentos", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoAtendimentos" */ "@/pages/CadClassificacaoAtendimentos.vue")
    }, {
        path: "/classificacaoAtendimento",
        name: "classificacaoAtendimentoInc", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoAtendimentoInc" */ "@/pages/CadClassificacaoAtendimento.vue")
    }, {
        path: "/classificacaoAtendimento/:id",
        name: "classificacaoAtendimentoAlt", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "classificacaoAtendimentoAlt" */ "@/pages/CadClassificacaoAtendimento.vue")
    }, {
        path: "/contasPaciente",
        name: "contasPaciente", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "contasPaciente" */ "@/pages/ContasPaciente.vue")
    }, {
        path: "/contasPaciente/:idAgenda",
        name: "contasPacienteAgenda", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "contasPaciente" */ "@/pages/ContasPaciente.vue")
    }, {
        path: "/fluxoDeCaixa",
        name: "fluxoDeCaixa", 
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "fluxoDeCaixa" */ "@/pages/FluxoDeCaixa.vue")
    }]
});